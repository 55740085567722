<template>
  <div class="container">
    <div class="card card-custom example example-compact">
      <form class="form" @submit.stop.prevent="onSubmit">
        <div class="card-body">
          <div class="form-group row">
            <label class="col-form-label text-right col-lg-3 col-sm-12">
              {{ $t("FORM.PASSWORD") }}
            </label>
            <div class="col-lg-6 col-md-9 col-sm-12">
              <div class="row col-12 position-relative align-items-center">
                <input
                  :type="pswdType"
                  class="form-control"
                  :class="{ 'border-danger': $v.form.password.$error }"
                  placeholder=""
                  v-model="$v.form.password.$model"
                  :state="validateState('password')"
                  aria-describedby="input-1-live-feedback"
                />
                <i
                  class="position-absolute right-0 mr-6"
                  :class="[
                    pswdType === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash'
                  ]"
                  @click="changePswdType"
                ></i>
              </div>
              <template v-if="$v.form.password.$error">
                <div
                  class="validation error text-danger"
                  v-if="!$v.form.password.required"
                >
                  {{ $t("AUTH.VALIDATION.REQUIRED_FIELD") }}
                </div>
                <div
                  class="validation error text-danger"
                  v-if="!$v.form.password.minLength"
                >
                  {{
                    $t("AUTH.VALIDATION.MIN_LENGTH", {
                      name: $t("FORM.PASSWORD"),
                      min: $v.form.password.$params.minLength.min
                    })
                  }}
                </div>
              </template>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label text-right col-lg-3 col-sm-12">
              {{ $t("FORM.PASSWORD_CONFIRM") }}
            </label>
            <div class="col-lg-6 col-md-9 col-sm-12">
              <div class="row col-12 position-relative align-items-center">
                <input
                  :type="pswdType"
                  class="form-control"
                  :class="{ 'border-danger': $v.form.password.$error }"
                  placeholder=""
                  v-model="$v.form.password_confirm.$model"
                  :state="validateState('password_confirm')"
                  aria-describedby="input-1-live-feedback"
                />
              </div>
              <template v-if="$v.form.password_confirm.$error">
                <div
                  class="validation error text-danger"
                  v-if="!$v.form.password_confirm.sameAsPassword"
                >
                  {{ $t("AUTH.VALIDATION.PASSWORD_DONT_MATCH") }}
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-lg-9 ml-lg-auto d-flex justify-content-end">
              <button type="submit" class="btn btn-success mr-2">
                {{ $t("FORM.SUBMIT") }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs";
import { CHANGE_PASSWORD } from "@/store/modules/profile";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

export default {
  mixins: [validationMixin],
  components: {},
  data() {
    return {
      pswdType: "password",
      form: {
        password: "",
        password_confirm: ""
      }
    };
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(6)
      },
      password_confirm: {
        sameAsPassword: sameAs("password")
      }
    }
  },
  methods: {
    validateState(title) {
      const { $dirty, $error } = this.$v.form[title];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.$store
        .dispatch(CHANGE_PASSWORD, {
          password: this.form.password
        })
        .then(() => {
          this.$nextTick(() => {
            this.$v.form.$reset();
          });
          this.form = {
            password: "",
            password_confirm: ""
          };
          this.$root.$bvToast.toast(this.$t("FORM.SUBMITTED"), {
            title: this.$t("FORM.SUCCESS_TITLE"),
            variant: "success",
            autoHideDelay: 5000
          });
        })
        .catch(err => {
          const msg = err?.response?.data?.message || this.$t("FORM.ERROR");
          this.$root.$bvToast.toast(msg, {
            title: this.$t("FORM.ERROR_TITLE"),
            variant: "danger",
            autoHideDelay: 5000
          });
        });
    },
    changePswdType() {
      this.pswdType = this.pswdType === "password" ? "text" : "password";
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("ROUTES.CHANGE_PASSWORD") }
    ]);
  }
};
</script>
